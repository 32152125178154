// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accident-insurance-js": () => import("./../../../src/pages/accident-insurance.js" /* webpackChunkName: "component---src-pages-accident-insurance-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dental-insurance-js": () => import("./../../../src/pages/dental-insurance.js" /* webpackChunkName: "component---src-pages-dental-insurance-js" */),
  "component---src-pages-employment-insurance-js": () => import("./../../../src/pages/employment-insurance.js" /* webpackChunkName: "component---src-pages-employment-insurance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscribirse-en-obamacare-js": () => import("./../../../src/pages/inscribirse_en_obamacare.js" /* webpackChunkName: "component---src-pages-inscribirse-en-obamacare-js" */),
  "component---src-pages-inscribirse-en-seguro-medico-js": () => import("./../../../src/pages/inscribirse-en-seguro-medico.js" /* webpackChunkName: "component---src-pages-inscribirse-en-seguro-medico-js" */),
  "component---src-pages-medical-insurance-js": () => import("./../../../src/pages/medical-insurance.js" /* webpackChunkName: "component---src-pages-medical-insurance-js" */),
  "component---src-pages-obamacare-insurance-js": () => import("./../../../src/pages/obamacare-insurance.js" /* webpackChunkName: "component---src-pages-obamacare-insurance-js" */),
  "component---src-pages-private-insurance-js": () => import("./../../../src/pages/private-insurance.js" /* webpackChunkName: "component---src-pages-private-insurance-js" */),
  "component---src-pages-vision-insurance-js": () => import("./../../../src/pages/vision-insurance.js" /* webpackChunkName: "component---src-pages-vision-insurance-js" */)
}

