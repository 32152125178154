/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"
// import DateFnsUtils from '@date-io/date-fns'

import { AuthProvider } from "./src/components/authContext"
import "./src/theme/styles.scss"

export const onRouteUpdate = () => {
  document.body.classList.remove("no-scroll")
}

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    {element}
  </AuthProvider>
)
