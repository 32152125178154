import React, { useState } from "react"
import PropTypes from "prop-types"

const defaultState = {
  user: null,
  handleLogin: () => { // FIXME solo para pruebas, eliminar esto al implementar autenticación con Keycloak
  },
  isLoggedIn: () => {
  },
  logout: () => {
  }
}

const AuthContext = React.createContext(defaultState)

const getCurrentUser = () =>
  typeof window !== "undefined" && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {}

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(getCurrentUser())

  const saveUserToLocalStorage = user =>
    window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

  // FIXME solo para pruebas, eliminar esto al implementar autenticación con Keycloak
  const handleLogin = () => {
    const userObject = {
      username: `antonio`,
      name: `Antonio`,
      email: `tony@example.com`
    }

    saveUserToLocalStorage(userObject)
    setUser(userObject)
  }

  const isLoggedIn = () => !!user && !!user.username

  const logout = () => {
    saveUserToLocalStorage(null)
    setUser(null)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        handleLogin,
        isLoggedIn,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthContext

export { AuthProvider }